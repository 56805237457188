<template>
    <div class="cart">
        <h3>Resumo de compra</h3>
        <ul>
            <li>
                <p>Descrição</p>
                <p>Valor</p>
            </li>
            <li v-for="(item, index) in products" :key="index">
                <p class="titulo">{{item.title}}</p>
                <p class="valor">R$ {{ real(item.unit_price) }}</p>
            </li>
            
            <li v-for="(tax) in taxs" :key="tax.title">
                
                <p class="titulo">Taxa <small>({{tax.title ? tax.title : 'Forma de pagamento'}})</small></p>
                <p class="valor">R$ {{ real(calcTax(tax)) }} </p>
                
            </li>
            
            <li>
                <p>Total</p>
                <p>R$ {{ real(amount) }}</p>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'CartItems',
    data () {
        return {
            
        }
    },
    methods: {
        ...mapActions('buy',['setTotal']),
        real(value){
            return value.toLocaleString('pt-BR',{minimumFractionDigits: 2, maximumFractionDigits: 2});
        },
        calcTax(tax){
            let resultTax = 0
            let products = 0

            this.products.map(prod => products += prod.unit_price)

            if(tax.type == "percent"){
                resultTax += (products*(tax.amount/100))
            }

            if(tax.type == "value"){
                resultTax = tax.amount
            }

            return resultTax

        },

        total(){ 
            let products = 0
            let total = 0

            this.products.map(prod => products += prod.unit_price)

            this.taxs.map(tax => {
                if(tax.type == 'value'){
                    total += tax.amount + products
                }

                if(tax.type == "percent"){
                    total += products + (products*(tax.amount/100))
                }

            })

            this.setTotal(parseFloat(total.toFixed(2)))
            
        }
    },
    computed: {
        ...mapGetters('buy',['products','amount','taxs']),        
    },
    watch:{
        taxs(){
            this.total()
        }
    }
}
</script>
<style lang="scss">
.cart{
    min-height: 100px;
    padding: 0 20px 40px;
    background: #FFF;

    @media(min-width: 768px){
        padding: 30px 25px 0;
        width: 35%;
    }

    @media(min-width: 992px){
        width: 30%;
    }

    ul{
        li{
            display: flex;
            justify-content: space-between;
            font-size: 13px;
            color:#777;
            border-bottom: 1px #eaeaea solid;
            padding: 6px 0;

            p{
                margin: 0;
            }

            &:first-child{
                p{
                    font-weight: 700;
                    color:#333;
                }
            }

            &:last-child{
                border-bottom: none;
                justify-content: flex-end;
                padding-top: 18px;

                p{
                    &:first-child{
                        font-weight: 700;
                        color:#333;
                        padding-right: 20px;
                    }
                }
            }
        }
    }
}

</style>


