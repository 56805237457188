<template>
    <ul id="stepList">
        <li @click="setStep(index)" role="tab" v-for="(item,index) in steps" :key="index" :class="{'current':currentIndexStep >= index }">
            <a href="#">
                <div class="title"><span class="step-number">{{ index+1 }}</span><span class="step-text">{{ item.title }}</span></div>
            </a>
        </li>
    </ul>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    name: 'StepList',
    computed: {
        ...mapGetters('steps',['currentIndexStep','currentStep','goToNextPage', 'steps']),
    },
    methods:{
        ...mapActions('steps',['changeStep','validate']),

        setStep(i){


            if(i <= this.currentIndexStep){
                this.changeStep(i)
                this.$router.push(this.currentStep)
            }


        }
    }
}
</script>

<style lang="scss">

@import '@/assets/css/common/extend.scss';
@import '@/assets/css/common/variables.scss';
@import '@/assets/css/common/minxi.scss';


#stepList {
    @extend list-type-ulli;
    position: relative;
    display: none;

    li {

        position: relative;
        z-index: 99;
        a {
            text-decoration: none;
            color: $black-color;

            .title {
                @extend display-flex;
                @include align-items(center);
                @include flex-direction(column);
                padding: 0 10px;

                .step-number {
                    width: 60px;
                    height: 60px;
                    @extend display-flex;
                    @include align-items(center);
                    @include justify-content(center);
                    @include border-radius(50%);
                    background: $border-color;
                    color: #999;
                    border: 5px solid #fff;
                    font-weight: bold;
                }
                .step-text {
                    font-weight: bold;
                    color: $grey-color;
                    text-align: center;
                    font-size: .9em;
                }
            }
        }

        &.current{
            a{
                .title{
                    .step-number{
                        background: #02436e;
                        color:#FFF;
                    }
                }
            }
        }
    }

    &:after {
        position: absolute;
        content: '';
        width: 60%;
        height: 2px;
        background: #ebebeb;
        left: 50%;
        top: 40px;
        @include transform(translateX(-50%));
        z-index: 9;
    }

    @media(min-width: 768px){
        display: flex;
        @include flex-direction(row);
        justify-content: space-between;
        padding: 15px 30px 0;
    }
}
</style>
