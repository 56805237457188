<template>
    <router-view/>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    name: 'App',
    created(){
        if(this.$route.query.transaction){
            this.setBilling(this.$route.query.transaction).then(status => {
                if(status){
                    this.$router.push({ name: 'pagamento',  query: { transaction: this.$route.query.transaction }}).catch(() => {})
                }
            })
        }
    },
    methods:{
        ...mapActions('steps',['setBilling'])
    }
}
</script>

<style lang="scss">
.footer-small{
    font-size: 11px;
}
</style>
