import axios from 'axios'
import store from '../store'

const $axios = axios.create({
    baseURL: process.env.VUE_APP_API,
    timeout: 60000,
});

export default {
    getFatura: () => {
        return $axios.get("fatura/"+store.getters['steps/getHash'])
    },
    
    sendPagamento: (data) => {
        return $axios.post("pagamento", data)
    },

    getFaturaPix: (fatura) => {
        return $axios.get("status-fatura/"+fatura)
    }
}