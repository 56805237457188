<template>
    <div class="px-4 py-4">
        <h2 class="pb-2">Confirme seu pedido</h2>
        <div class="card">
            <p class="section-title">Dados do pagamento</p>
            <span>
                Forma de Pagamento: {{ paymentMethodString }}
                <div v-if="paymentMethod == 'credit_card'">
                    <br>
                    <span class="upper">{{ creditCard.flagCard }}</span> <br>
                    {{ creditCard.name }} <br>
                    {{ creditCard.number }} <br>
                    Válido até {{ creditCard.validMonth }}/{{ creditCard.validYear }}
                </div>
            </span>
        </div>
        <div class="card mt-3">
            <p class="section-title">Dados da Comprador</p>
            <span>
                {{ userPayment.first_name + " " + userPayment.last_name }} <br>
                {{ userPayment.document }} 
                <div v-if="userInfo.length > 0">
                    <br>
                    Praça José Assunção, 1, 91900-450 <br>
                    Vila Assunção - Porto Alegre/RS
                </div>
            </span>
        </div>
        <div class="mt-3">
            <small>
                <b>*Atenção:</b> Ao gerar este pedido, nosso sistema irá comunicar-se com a instituição financeira que, ao identificar o pagamento, liberará AUTOMATICAMENTE o produto adquirido, <b>de acordo com os respectivos prazos de cada forma de pagamento</b>.<br>
                A ConsultaOn não tem qualquer bloqueio ou análise interna que altere este prazo ou interfira na aprovação dos pedidos.<br><br>
                <b>Você receberá um e-mail informando o status do seu pedido.</b>
            </small>
        </div>
    </div>
</template>
<script>
import Swal from 'sweetalert2'
import { mapGetters, mapActions } from 'vuex'
import Service from '@/service/index'
export default {
    data(){
        return{
            request:{}
        }
    },
    created(){
        this.$root.$on('submit', (data) => {
            if(data == "confirmacao"){
                this.confirmPayment()
            }
        })

        this.request ={
            items: this.products,
            external_reference: this.getHash,
            total: this.amount
        }
    },
    computed:{
        ...mapGetters("user",['userPayment','userInfo']),
        ...mapGetters("steps",['getHash']),
        ...mapGetters("buy",['paymentMethod', 'creditCard','amount','products']),
        paymentMethodString(){
            let payment = ""

            switch(this.paymentMethod.method){
                case 'credit_card':
                    payment = 'Cartão'
                    break;
                case 'bolbradesco':
                    payment = 'Boleto'
                    break;
                case 'pix':
                    payment = "Pix"
                    break;
            }

            return payment
        }
    },
    methods:{
        ...mapActions('steps',['reset']),

        confirmPayment(){
            Swal.fire({
                title: 'Tem certeza?',
                text: "Deseja concluir esta operação?",
                icon: "question",
                confirmButtonText: "Sim, realizar pagamento",
                confirmButtonColor: "#4BB543",
                showCancelButton:true,
                cancelButtonText: "Não, cancelar",
                cancelButtonColor: "#cf142b",
                footer: "Ao prosseguir você concorda com os <a class='px-1' href=''>Termos de uso</a> do ConsultaON",
                allowOutsideClick: false,
                customClass:{
                    footer: 'footer-small'
                }
            }).then(response => {
                if(response.isConfirmed){
                    this.payment()
                }
            })
        },
        payment(){
            Swal.fire({
                title: 'Enviando a solicitação',
                didOpen: () => {
                    Swal.showLoading()
                }
            })

            switch(this.paymentMethod.method){
                case 'bolbradesco':
                    this.paymentWithBolet()
                    break;
                case 'pix':
                    this.paymentWithPix();
                    break;
                case 'credit_card':
                    this.paymentWithCard();
                    break;
            }


            window.localStorage.removeItem('vuex')
            
        },

        paymentWithCard(){
            const data = {
                ...this.request,
                payer: {
                    type: this.creditCard.docType,
                    number: this.creditCard.docNumber,
                    email: this.userInfo.nm_email
                },
                installments: 1,
                token: this.creditCard.token,
                payment_method_id: this.creditCard.flagCard
            }

            Service.sendPagamento(data).then(response => {
                window.localStorage.setItem("response",JSON.stringify(response.data))
                this.$router.push('success')
                Swal.close()
            }).catch(() =>{
                Swal.close()

                Swal.fire({
                    title:'Erro',
                    text: "Ocorreu um erro, entre em contato com administrador",
                    icon: "error"
                })
            })
        },

        paymentWithBolet(){
            const data = {
                ...this.request,
                payer: {
                    type: this.userPayment.document.length > 14 ? 'CNPJ' : 'CPF',
                    number: this.userPayment.document,
                    first_name: this.userPayment.first_name,
                    last_name: this.userPayment.last_name,
                    email: this.userInfo.nm_email
                },
                payment_method_id: this.paymentMethod.method
            }

            Service.sendPagamento(data).then(response => {
                window.localStorage.setItem("response",JSON.stringify(response.data))
                this.$router.push('success')
                Swal.close()
            }).catch(() =>{
                Swal.close()

                Swal.fire({
                    title:'Erro',
                    text: "Ocorreu um erro, entre em contato com administrador",
                    icon: "error"
                })
            })
        },

        paymentWithPix(){
            const data = {
                ...this.request,
                payer: {
                    type: this.userPayment.document.length > 14 ? 'CNPJ' : 'CPF',
                    number: this.userPayment.document,
                    first_name: this.userPayment.first_name,
                    last_name: this.userPayment.last_name,
                    email: this.userInfo.nm_email
                },
                payment_method_id: this.paymentMethod.method
            }

            Service.sendPagamento(data).then(response => {
                window.localStorage.setItem("response",JSON.stringify(response.data))
                this.$router.push('success')
                Swal.close()
            }).catch(() =>{
                Swal.close()
                
                Swal.fire({
                    title:'Erro',
                    text: "Ocorreu um erro, entre em contato com administrador",
                    icon: "error"
                })
            })
        },

        resetData(){

        }
    }
}
</script>
<style lang="scss">
@import '@/assets/css/style.scss';

.card{
    background-color: #FFF;
    border-radius: 6px;
    padding: 12px 15px;
}

.card .section-title{
    font-weight: 700;
    color:#000;
    margin:0 0 6px 0;
}

.swal-footer, .footer-small{
    font-size: 11px;
}

.upper{
    text-transform: uppercase;
}
</style>
