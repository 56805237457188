<script>
import VeeValidate from 'vee-validate'
import { Validator } from 'vee-validate';

VeeValidate.Validator.extend('document', {
    getMessage: () => 'CPF ou CNPJ inválido',
    validate: isDocumentValid
});

function isCPF(strCPF){
    var Soma;
    var Resto;
    var i;
    Soma = 0;
    if (strCPF == "00000000000") return false;
    for (i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;
    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;
    Soma = 0;
    for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;
    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
}

function isCNPJ(cnpj){
    var tamanho;
    var numeros;
    var digitos;
    var soma;
    var pos;
    var resultado;
    var i;

    cnpj = cnpj.replace(/[^\d]+/g,'');

    if(cnpj == '') return false;

    if (cnpj.length != 14)
        return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999")
        return false;

    // Valida DVs
    tamanho = cnpj.length - 2
    numeros = cnpj.substring(0,tamanho);
    digitos = cnpj.substring(tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
        return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
        return false;

    return true;
}

function isDocumentValid(value){
    value = _replaceAll(value,".","")
    value = _replaceAll(value,"-","")
    value = _replaceAll(value,"/","")

    return isCPF(value) || isCNPJ(value)
}

function _replaceAll(str, find, replace) {
    return str.replace(new RegExp(_escapeRegExp(find), 'g'), replace);
}

function _escapeRegExp(str) {
    return str.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
}

const dict ={
    custom: {
        first_name: {
            required: () => 'Digite seu primeiro nome corretamente',
            min: () => "Mínimo de 3 caracteres"
        },
        last_name: {
            required: () => 'Digite seu último nome corretamente',
            min: () => "Mínimo de 3 caracteres"
        },
        document: {
            required: () => 'Digite o campo corretamente',
            min: () => "Mínimo de 3 caracteres"
        }
    }
}

Validator.localize('en', dict)


export default {
    mounted(){

    },
    methods:{

    }
}
</script>
