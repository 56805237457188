export default {
    namespaced: true,
    state:{
        paymentMethod: "",
        credit_card:{},
        amount: 0,
        tax:[],
        products:[]
    },
    mutations:{
        SET_PAYMENT(state, value){
            state.paymentMethod = value
        },
        ADD_TAX(state, value){
            state.tax.push(value)
        },
        DEL_TAX(state, value){
            state.tax.splice(state.tax.findIndex((item) => item.key == value ),1)
        },
        SET_TAX(state, value){
            state.tax = value
        },
        SET_AMOUNT(state, value){
            state.amount = value
        },
        SET_PRODUCTS(state, value){
            state.products = value
        },
        SET_CREDIT(state, value){
            state.credit_card = value
        }
    },
    actions:{
        setProdutos({ commit }, products){
            const data = [
                {
                    unit_price: products.amount, 
                    title: products.name,
                    quantity: products.quantity ? products.quantity : 1
                }
            ]

            commit("SET_PRODUCTS", data)
            commit("SET_AMOUNT", products.amount)
        },

        setPayment({ commit }, data){
            commit("SET_PAYMENT", data.method)    
            commit("SET_CREDIT", data.card)
        },

        addTax({commit}, data){
            commit("ADD_TAX",data)
        },

        removeTax({commit}, data){
            commit("DEL_TAX",data)
        },

        resetTax({commit}){
            commit("SET_TAX",[])
        },

        setTotal({commit}, value){
            commit("SET_AMOUNT", value)
        }
    },
    getters:{
        paymentMethod: state => state.paymentMethod,
        amount: state => state.amount,
        taxs: state => state.tax,
        products: state => state.products,
        creditCard: state => state.credit_card
    }
}
