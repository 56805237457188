<template>
    <div class="buttons">
        <button @click="anteriorStep" v-if="currentIndexStep > 0 " class="btn btn-secondary">Voltar</button>
        <button @click="submit" class="btn success">{{ nextButton }}</button>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data(){
        return{
            nextButton: "Próximo"
        }
    },
    computed:{
        ...mapGetters('steps',['currentIndexStep','currentStep', 'steps'])
    },
    methods:{
        ...mapActions('steps',['subStep']),

        submit(){
            this.$root.$emit('submit', this.currentStep);
        },
        anteriorStep(){
            this.subStep()
            
            this.$router.push(this.currentStep).catch(() => {})
        }
    },
    watch:{
        currentIndexStep(){
            if(this.currentIndexStep+1 == this.steps.length){
                this.nextButton = "Finalizar"
            }else{
                this.nextButton = "Próximo"
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.buttons .btn{
    outline: none;
    color:#FFF;

    &:hover, &:active{
        outline: none;
        color:#FFF;
    }
}
</style>