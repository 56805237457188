export default {
    namespaced: true,
    state:{
        currentStep: 0,
        goToNextPage: false,
        nextButton: "Próximo",
        hash: "",
        steps: [
            {
                route:'pagamento',
                title: "Formas de Pagamento"
            },
            {
                route: 'dados',
                title: "Dados Pessoais"
            },
            {
                route: 'confirmacao',
                title: "Confirmação"
            },
        ]
    },
    mutations:{
        NEXT_STEP(state, value){
            state.currentStep = value
        },

        NEXT_PAGE_PERMISSION(state, value){
            state.goToNextPage = value
        },
        SET_STEP(state, value){
            state.currentStep = value
        },
        SET_HASH(state, value){
            state.hash = value
        }
    },
    actions:{
        addStep({ commit, state }, step){
            if(step == null){
                let current = parseInt(state.currentStep)

                if(current + 1 <= state.steps.length){
                    if(current+1 == state.steps.length){
                        state.nextButton = "Finalizar"
                    }

                    commit("SET_STEP", (current + 1))
                }
            }else{
                commit("SET_STEP", step)
            }
        },

        subStep({ commit, state }){
            let current = parseInt(state.currentStep)

            if(current - 1 >= 0){
                commit("SET_STEP", (current - 1))
            }
        },

        validate({ commit }, status = true){
            commit("NEXT_PAGE_PERMISSION", status)
        },

        changeStep({ commit }, step){
            commit("SET_STEP", step)
        },

        setBilling({ commit, state }, value){
            
            if(state.hash !== value){
                console.log("essa HASH é diferente da ultima");

                commit("SET_HASH", value)
                commit("SET_STEP", 0)
                state.nextButton = "Próximo"

                commit('buy/SET_PAYMENT', "", { root: true })
                commit('buy/SET_CREDIT', "", { root: true })
                commit('buy/SET_AMOUNT', 0, { root: true })
                commit('buy/SET_PRODUCTS', [], { root: true })
                commit('buy/SET_TAX', [], { root: true })
                                
                commit('user/SET_USER', {}, { root: true })
                commit('user/SET_PAYMENT_USER', {}, { root: true })

                return true
            }

            return false
        }
    },
    getters:{
        goToNextPage: state => state.goToNextPage,
        currentIndexStep: state => state.currentStep,
        currentStep: state => state.steps[state.currentStep].route,
        currentStepName: state => state.steps[state.currentStep].title,
        steps: state => state.steps,
        nextButton: state => state.nextButton,
        getHash: state => state.hash
    }
}
