import Vue from 'vue'
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist'

import steps from './modules/steps'
import buy from './modules/buy'
import user from './modules/user'

Vue.use(Vuex);


const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})

export default new Vuex.Store({
    modules:{
        steps,
        user,
        buy
    },
    plugins: [vuexLocal.plugin]
})
