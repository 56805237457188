//import MP from '../../service/mercadopago'

export default {
    namespaced: true,
    state:{
        user: {},
        payment_user: {},
    },
    mutations:{
        SET_USER(state, value){
            state.user = value
        },
        SET_PAYMENT_USER(state, value){
            state.payment_user = value
        },
    },
    actions:{
        saveUserAll({commit}, user){
            commit("SET_USER", user)
        },
        saveUser({commit}, user){
            commit("SET_PAYMENT_USER", user)
        }
    },
    getters:{
        userInfo: (state) => state.user,
        userPayment: (state) => state.payment_user
    }
}
