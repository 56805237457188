// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import VeeValidate from 'vee-validate'
import VueTheMask from 'vue-the-mask'
import LottiePlayer from 'lottie-player-vue';

Vue.use(VeeValidate,{
    events: ''
});

Vue.use(VueTheMask)
Vue.use(LottiePlayer);

Vue.config.productionTip = false


window.Mercadopago.setPublishableKey(process.env.VUE_APP_MP_ENV == 'dev' ? process.env.VUE_APP_MP_PUBLIC_DEV : process.env.VUE_APP_MP_PUBLIC);


new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
  