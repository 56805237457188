<template>
    <fieldset id="steps-uid-0-p-0" role="tabpanel" aria-labelledby="steps-uid-0-h-0" class="body current d-block" aria-hidden="false">
        <h2>Dados Pessoais</h2>
        <p class="desc">
            Insira seus dados pessoais para identificarmos o comprador, tendo em vista a segurança da sua compra.
        </p>
        <form data-vv-scope="form-dados">
            <div class="fieldset-content">
                <div class="form-row">
                    <label class="form-label">Nome</label>
                    <div class="form-flex">
                        <div :class="{'form-group': true, error: errors.has('form-dados.first_name')}">
                            <input v-validate="{required: true, alpha_spaces: true, min:3}" v-model="form.first_name" type="text" name="first_name">
                            <span class="text-input">Primeiro nome</span>
                            <div class="help-block">{{ errors.first('form-dados.first_name') }}</div>
                        </div>
                        <div :class="{'form-group': true, error: errors.has('form-dados.last_name')}">
                            <input v-validate="{required: true, alpha_spaces: true, min:3}" v-model="form.last_name" type="text" name="last_name">
                            <span class="text-input">Sobrenome</span>
                            <div class="help-block">{{ errors.first('form-dados.last_name') }}</div>
                        </div>
                    </div>
                </div>
                <div :class="{'form-group': true, error: errors.has('form-dados.document')}">
                    <label for="_documento" class="form-label">CPF / CNPJ</label>
                    <input v-mask="['###.###.###-##','##.###.###/####-##']" v-validate="{required: true, min:3, document: true}" v-model="form.document" type="text" name="document" id="_documento">
                    <div class="help-block">{{ errors.first('form-dados.document') }}</div>
                </div>
            </div>
        </form>
    </fieldset>
</template>

<script>
import ValidacoesMixins from '@/mixins/ValidacoesMixins'
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'DadosPage',
    mixins:{
        ValidacoesMixins
    },
    data () {
        return {
            form: {
                first_name: '',
                last_name: '',
                document: ''
            },
            dict: {
                custom: {
                    first_name: {
                        required: () => 'Digite seu primeiro nome corretamente',
                        min: () => "Mínimo de 3 caracteres",
                        alpha_spaces: () => "Somente caracteres válidos. Números e caracteres especiais não são permitidos."
                    },
                    last_name: {
                        required: () => 'Digite seu último nome corretamente',
                        min: () => "Mínimo de 3 caracteres",
                        alpha_spaces: () => "Somente caracteres válidos. Números e caracteres especiais não são permitidos."
                    },
                    document: {
                        required: () => 'Digite o campo corretamente',
                        min: () => "Mínimo de 3 caracteres"
                    }
                }
            },
        }
    },
    created(){
        this.$validator.reset()

        this.fetchUser()

        this.$root.$on('submit', (data) => {

            if(data == "dados"){
                this.dadosSubmit()
            }
        })
    },
    computed:{
        ...mapGetters('steps',['goToNextPage','currentStep']),
        ...mapGetters('user',['userInfo','userPayment']),

    },
    methods:{
        ...mapActions('steps',['addStep']),
        ...mapActions('user',['saveUser']),

        dadosSubmit () {

            this.$validator.validateAll('form-dados').then(data => {
                if(data === true){
                    this.saveUser(this.form)
                    this.addStep(2)
                    this.$router.push(this.currentStep).catch(() => {})

                } else {
                    setTimeout(() =>{
                        this.$validator.reset()
                    },5000)
                }
            });
        },

        fetchUser(){
            if(this.userPayment && this.userPayment.first_name){
                this.form = this.userPayment
            }
        }
    },
}
</script>

<style lang="scss">

</style>

