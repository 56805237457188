<template>
    <fieldset id="steps-uid-0-p-0" role="tabpanel" aria-labelledby="steps-uid-0-h-0" class="body current d-block" aria-hidden="false">
        <h2>Formas de Pagamento</h2>
        <p class="desc">
            Escolha a melhor forma para efetuar o pagamento.
        </p>
        <div class="form-radio-flex">
            <div :class="{'form-radio-item': true, 'error': submitted && method == ''}" v-for="(item, index) in paymentMethods" :key="index">
                <input type="radio" :id="`opt${index}`" name="payment" :value="item" v-model="method">
                <label :for="`opt${index}`">
                    <i v-if="item.icon" :class="item.icon"></i>
                    <img v-if="item.img" :src="item.img" alt="" />
                    <p>{{item.title}}</p>
                </label>
            </div>
        </div>
        <div class="forms">
            <div class="credit" v-if="method.method == 'credit_card' ">
                <form @submit="validar()" data-vv-scope="form-pagamento">
                    <div class="row">
                         <div class="col-12 col-lg-6">
                            <div :class="{'form-group': true, error: errors.has('form-pagamento.docType')}">
                                <label class="form-label">Tipo de Documento</label>
                                <select name="docType" v-validate="{required: true}" v-model="cardForm.docType">
                                    <option hidden selected value="">SELECIONE...</option>
                                    <option value="CPF">CPF</option>
                                    <option value="CNPJ">CNPJ</option>
                                </select>
                            </div>
                        </div>
                         <div class="col-12 col-lg-6">
                            <div :class="{'form-group': true, error: errors.has('form-pagamento.docNumber')}">
                                <label class="form-label">Número do Documento</label>
                                <input v-mask="mask" name="docNumber" v-validate="{required: true, min:3}" type="text" v-model="cardForm.docNumber">
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div :class="{'form-group': true, error: errors.has('form-pagamento.name')}">
                                <label class="form-label">Nome do Titular</label>
                                <input v-validate="{required: true, alpha_spaces: true, min:3}" type="text" v-model="cardForm.name" name="name" v-uppercase>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div :class="{'form-group': true, error: errors.has('form-pagamento.number')}">
                                <label class="form-label">Número do Cartão</label>
                                <input v-mask="creditMask" @blur="guessPaymentMethod" v-validate="{required: true, credit_card: true}" type="text" name="number" v-model="cardNumber">
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="form-date">
                                <label for="birth_date" class="form-label">Validade</label>
                                <div class="form-date-group d-flex flex-row">
                                    <div :class="{'form-date-item': true, error: errors.has('form-pagamento.validMonth')}">
                                        <select  v-validate="{required: true}" id="birth_date" v-model="cardForm.validMonth" name="validMonth">
                                            <option value="" disabled>Mês</option>
                                            <option v-for="i in 31" :key="i">{{ i }}</option>
                                        </select>
                                    </div>
                                    <div :class="{'form-date-item': true, error: errors.has('form-pagamento.validYear')}">
                                        <select  v-validate="{required: true}" id="birth_year" v-model="cardForm.validYear" name="validYear">
                                            <option value="" disabled>Ano</option>
                                            <option v-for="i in years" :key="i">{{ i }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div :class="{'form-group': true, error: errors.has('form-pagamento.ccv')}">
                                <label class="form-label">CCV</label>
                                <input v-mask="'###'"  v-validate="{required: true}" type="text" name="ccv" v-model="cardForm.ccv">
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="boleto" v-if="method.method == 'bolbradesco'">
                <p>
                    <b>*Atenção:</b> O prazo de compensação do pagamento do boleto na instituição financeira que vai recebê-lo é de até 2 dias úteis, podendo ser concluído antes.<br>
                    Você receberá um e-mail informando o status do seu pedido.<br><br>
                    A data de pagamento será considerada de acordo com as <a href="https://www.mercadopago.com.br/ajuda/15660" target="blank">regras dos horários permitidos</a>.
                </p>
            </div>
            <div class="transfer" v-if="method.method == 'pix'">
                <p>
                    <b>*Importante:</b> O prazo de compensação do pagamento por PIX é imediato, assim que a instituição financeira identificar o pagamento, o pedido será liberado.<br><br>
                    Você receberá um e-mail informando o status do seu pedido.
                </p>
            </div>
        </div>
    </fieldset>
</template>

<script>
import Swal from 'sweetalert2';
import { mapActions, mapGetters } from 'vuex';
//import helpers from '@/directives'
export default {
    name: 'FormasPagamentoPage',
    directives:{
        uppercase: {
            update: function(el){
                el.value = el.value.toUpperCase()
            }
        }
    },
    data () {
        return {
            method : '',
            paymentMethods:[
                {
                    method: "credit_card",
                    icon: "fa fa-credit-card",
                    title: "Cartão de Crédito",
                    tax:{
                        type: "percent",
                        amount: 3,
                        title: "Cartão"
                    }
                },
                {
                    method: "bolbradesco",
                    icon: "fa fa-barcode",
                    title: "Boleto",
                    tax:{
                        type: "value",
                        amount: 2,
                        title: "Boleto"
                    }
                },
                {
                    method: "pix",
                    icon: "",
                    img: require("@/assets/img/pix.png"),
                    title: "Pix",
                    tax:{
                        type: "percent",
                        amount: 0,
                        title: "Pix"
                    }
                },
            ],
            submitted: false,
            mask: "###.###.###-##",
            creditMask: "#### #### #### ####",
            cardForm:{
                name: "",
                number: "",
                validMonth: "",
                validYear:"",
                ccv: "",
                paymentMethod: "",
                docType: "",
                docNumber: "",
                issuer: ""
            },
            cardNumber: "",
            issuers: []
        }
    },
    computed: {
        ...mapGetters('steps', ['currentStep','currentIndexStep', 'goToNextPage']),
        ...mapGetters('buy', ['paymentMethod','creditCard']),
        ...mapGetters('user',['userInfo']),
        years(){
            const now = new Date().getUTCFullYear();
            return Array(now - (now - 15)).fill('').map((v, idx) => now + idx);
        }
    },
    created(){
        this.$validator.reset();
        this.method = this.paymentMethod ? this.paymentMethod : ""
        this.cardForm = {...this.cardForm, ...this.creditCard}

        if(!this.creditCard.docType){
            this.cardForm.docType = this.userInfo.ic_tipo_pessoa == "PF" ? "CPF" : "CNPJ"
            this.cardForm.docNumber = this.userInfo.ic_tipo_pessoa == "PF" ? this.userInfo.nm_cpf : this.userInfo.nm_cnpj
        }

        this.$root.$on('submit', (data) => {
            this.submitted = true

            if(data == "pagamento"){              
                switch(this.method.method){
                    case 'credit_card':
                        this.validar()
                        break;
                    case 'bolbradesco':
                    case 'pix':
                        this.setPayment({method:this.method, card:''})
                        this.addStep(1)
                        this.$router.push(this.currentStep).catch(() => {})
                        break;
                }                
            }
        })
    },
    methods:{
        ...mapActions('steps',['validate','addStep']),
        ...mapActions('buy',['setPayment','addTax','resetTax']),
        validar(){

            this.$validator.validateAll('form-pagamento').then(async data => {
                if(data){
                
                    this.getCardToken().then(() =>{
                        this.addStep(1)
                        this.$router.push(this.currentStep).catch(() => {})
                    }).catch(err => {
                        Swal.fire({
                            icon:'warning',
                            title: 'Ei, temos um problema',
                            text: err.message
                        })
                    })
                    
                }
            });

        },

        guessPaymentMethod() {  
            this.cardForm.number = this.cardNumber.split(" ").join("")

            if (this.cardForm.number.toString().length >= 6) {
                let bin = this.cardForm.number.toString().substring(0,6);
                  
                window.Mercadopago.getPaymentMethod({
                    "bin": bin
                }, this.setPaymentMethod);
            }
        },
        
        setPaymentMethod(status, response) {
            if (status == 200) {
                let paymentMethod = response[0];
                this.cardForm.paymentMethod = paymentMethod.id
            }
        },

        async getCardToken(){ 
            return new Promise((resolve,reject) =>{
                window.Mercadopago.createToken({
                    cardNumber: this.cardForm.number,
                    cardholderName: this.cardForm.name,
                    cardExpirationMonth: this.cardForm.validMonth,
                    cardExpirationYear: this.cardForm.validYear,
                    securityCode: this.cardForm.ccv,
                    docType: this.cardForm.docType,
                    docNumber: this.cardForm.docNumber

                }, (status,response) => {            

                    if(status == 200 || status == 201){

                        let card = {
                            name: this.cardForm.name,
                            number: this.cardForm.number,
                            validMonth: this.cardForm.validMonth,
                            validYear: this.cardForm.validYear,
                            flagCard: this.cardForm.paymentMethod,
                            docType: this.cardForm.docType,
                            docNumber: this.cardForm.docNumber,
                            token: response.id,
                            issuer: this.cardForm.issuer
                        }

                        this.setPayment({method:this.method, card: card})

                        resolve()
                    }

                    reject(response)
                });            
            })           
        },

    },
    watch: {
        'cardForm.docType'(value){
            if(value == "CPF"){
                this.mask = "###.###.###-##"
            }

            if(value == "CNPJ"){
                this.mask = "##.###.###/####-##"
            }
        },
        method(newVal){
            if(newVal.tax){
                this.resetTax()
                this.addTax(newVal.tax)
            }
        }
    }
}
</script>

<style lang="scss">
@import url('../assets/css/font-awesome.min.css');
.form-radio-item.error label {
    border:1px red solid;
}
.form-radio-flex {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    .form-radio-item {
        padding: 0 15px;
        margin-bottom: 25px;
        -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
        width: 33.333%;
    }
    input {
        width: 0;
        height: 0;
        position: absolute;
        left: -9999px;
    }
	input + label {
        margin: 0px;
        width: auto;
        height: 133px;
        box-sizing: border-box;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: #FFF;
        border: 1px solid transparent;
        text-align: center;
        text-transform: none;
		transition: border-color .15s ease-out,
					color .25s ease-out,
					background-color .15s ease-out,
                    box-shadow .15s ease-out;

        i{
            font-size: 60px;
        }

        p{
            position: absolute;
            bottom: -3px;
        }

    }
	input:checked + label {
        border: 1px solid #4966b1;
        z-index: 1;
    }
    input:focus + label {
        outline: none;
    }
    input:hover {
        border: 1px solid #4966b1;
    }
}
</style>

