<template>
<div class="main page-success">
    <div class="box container pl-0 pr-0">
        <div role="application" class="vertical">
            <div class="content clearfix">
                <div align=center v-if="response.payment_type_id != 'bank_transfer' || paymentSuccess">
                    <lottie-player :src="success" :options="options" />
                    <h1 class="mb-1">Pedido concluído</h1>
                </div>
                
                <div v-if="response.payment_type_id =='ticket'">
                    <a target="_blank" :href="response.bolet" class="btn btn-secondary">Clique aqui para visualizar o boleto</a>    
                </div>
                <div v-if="response.payment_type_id =='credit_card'">
                    <p class="mt-1">Estamos processando seu pagamento, aguarde o email de confirmação para liberação do seu pedido.</p>
                </div>
                <div align=center v-if="response.payment_type_id =='bank_transfer' && paymentSuccess == false">

                    <img width="200" :src="`data:image/jpeg;base64,${response.qrcode}`"/>
                    <p class="mt-1">Escaneie o QR Code acima no aplicativo do seu banco para fazer a transferência imediata.</p>

                    <p class="mt-1">Ou use o pix copia e cola no seu aplicativo para pagar.</p>
                    <small>{{ response.pixtext }}</small>
                    <p><button class="btn-secondary btn" @click="copy()">Clique para copiar</button></p>
                </div>
                <br><br><br>
                <a href="https://www.consultaon.com.br/sistema/consultar" class="btn btn-sm btn-primary">Painel de Consultas <i class="fa fa-external-link-square" aria-hidden="true"></i></a>
            </div>    

        </div>
    </div>
</div>
</template>

<script>
import success from '@/assets/success.json';
import Service from '@/service'

export default {
    data(){
        return {
            response: {},
            success: success,
            interval: null,
            paymentSuccess: false,
            options: {
                autoplay: true,
                speed: 0.8,
                width: '350px'
            }
        }
    },

    created(){
        if(!window.localStorage.getItem('response')){
            this.$router.push('notfound')
        }
        this.response = JSON.parse(window.localStorage.getItem('response'))
        if(this.response.payment_type_id == 'bank_transfer'){
            this.interval = setInterval(() => {
                this.checkPix(this.response.external_reference)
            }, 3000);
        }else{
            this.paymentSuccess = 1
        }
    },
    mounted(){
        window.localStorage.removeItem('response')
    },
    methods: {
        copy(){
            navigator.clipboard.writeText(this.response.pixtext)
        },
        checkPix(id){
            Service.getFaturaPix(id).then(response => {
                if(response.data.status == 1){
                    this.paymentSuccess = 1
                    clearInterval(this.interval)
                }
            })
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/css/style.scss';
.page-success{

    .main {
        position: relative;
        z-index: 99;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }

    .box.container {
        width: calc(100% - 40px);
        max-width: 100%;
        position: relative;
        background: #fff;
        border-radius: 15px;
        overflow: hidden;

        @media(min-width: 1000px){
            width: 90%;
            max-width: 1300px;
        }
    }

    .content{
        background: #f8f8f8;
        order:1;
        padding-bottom: 90px;
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }


    .vertical{
        display: flex;


        @media(min-width: 768px){
            flex-direction: row;
        }

        @media(min-width: 1000px){
            min-height: 80vh;
        }
    }

    fieldset {
        padding: 0;
        margin: 0;
        border: none;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 30px;
        display: flex;
        position:relative;

        @media(min-width: 768px){
            padding-left: 30px;
            padding-right: 30px;
            padding-top: 45px;
        }

    }

    p.desc {
        margin: 0px;
        margin-bottom: 40px;
        color: #555;
    }

    .btn{
        width: auto;
        height: auto;
    }
}

</style>

