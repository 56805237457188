import Vue from 'vue'
import Router from 'vue-router'

import Dashboard from '@/layout/Dashboard'
import DadosPage from '@/pages/DadosPage'
import FormasPagamentoPage from '@/pages/FormasPagamentoPage'
import ConfirmacaoPage from '@/pages/ConfirmacaoPage'
import NotFound from '@/layout/404'
import Success from '@/layout/Success'

import store from '../store'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Dashboard',
            component: Dashboard,
            redirect: '/pagamento',
            children: [
                {
                    path: '/pagamento',
                    component: FormasPagamentoPage,
                    name: 'pagamento'
                },
                {
                    path: '/dados',
                    component: DadosPage,
                    name: 'dados'
                },

                {
                    path: '/confirmacao',
                    component: ConfirmacaoPage,
                    name: 'confirmacao'
                },
            ]
        },
        {
            path: "/notfound",
            name: "notfound",
            component: NotFound
        },
        {
            path: "/success",
            name: "success",
            component: Success
        }
    ]
})

function hasQueryParams(route) {
    return route.query.transaction ? Object.keys(route.query.transaction).length : null
}

router.beforeEach((to,from, next) => {

    let currentPage = store.getters['steps/currentStep']
    let indexPage = store.getters['steps/currentIndexStep']
    let checkRouteIndexStep = store.getters['steps/steps'].findIndex((step) => step.route == to.name)
    let permissionPages = ['notfound', 'success']
    let find = permissionPages.find(el => el == to.name)

    let transaction = to.query.transaction
    

    if(!hasQueryParams(to) && hasQueryParams(from) &&  !find){
        next({name: to.name, query: from.query});
        return;
    }
    if(transaction === undefined &&  !find){
        next("/notfound/")
        return;
    }

    if((to.name !== currentPage && indexPage !== checkRouteIndexStep && transaction !== undefined)){
        next({name: currentPage, query: {transaction: transaction}})
        return;
    }



    next()

})

export default router
