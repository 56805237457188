<template>
<div class="main">
    <div class="box container pl-0 pr-0">
        <div v-if="!loading" role="application" class="vertical">
            <cart-items/>

            <div class="content">
                <step-list></step-list>
                <transition name="fade">
                    <keep-alive>
                        <router-view :key="$route.fullPath" max="5"></router-view>
                    </keep-alive>
                </transition>
                <step-buttons></step-buttons>
            </div>

        </div>
    </div>
</div>
</template>

<script>
import CartItems from '@/components/CartItems';
import StepList from '@/components/StepList'
import StepButtons from '@/components/StepButtons'
import { mapActions } from 'vuex'
import Swal from 'sweetalert2';
import Service from '@/service/index'

export default {
    name: 'DashboardPage',
    components: {
        CartItems,
        StepList,
        StepButtons
    },
    data(){
        return {
            loading: true
        }
    },
    created(){
        Swal.fire({
            title: "Carregando...",
            didOpen: () => {
                Swal.showLoading()
            }
        })

        Service.getFatura().then(response => {            
            Swal.close()
            this.loading = false
            this.setProdutos(response.data.data)

            const user = response.data.data.user
            let name = user.ic_tipo_pessoa.toUpperCase() == "PF"? user.nm_nome_usuario.split(" ") : user.nm_responsavel.split(" ")
            const doc = user.ic_tipo_pessoa.toUpperCase() == "PF" ? user.nm_cpf : user.nm_cnpj

            this.saveUser({
                first_name:  name[0],
                last_name: name[1],
                document: doc
            })

            this.saveUserAll(user)

        }).catch(() => {
            this.$router.push('notfound')
        }).finally(() => {
            Swal.close()
        })
    },

    methods: {
        ...mapActions('buy', ['setProdutos']),
        ...mapActions('user', ['saveUser','saveUserAll'])

    }
}
</script>

<style lang="scss">
    @import '@/assets/css/style.scss';

    .swal-footer, .footer-small{
        font-size: 11px !important;
    }

    .main {
        position: relative;
        z-index: 99;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }

    .box.container {
        width: calc(100% - 40px);
        max-width: 100%;
        position: relative;
        background: #fff;
        border-radius: 15px;
        overflow: hidden;

        @media(min-width: 1000px){
            width: 90%;
            max-width: 1300px;
        }
    }

    .content{
        background: #f8f8f8;
        order:1;
        padding-bottom: 90px;
        position: relative;

        @media(min-width: 768px){
            width: 70%;
            padding-bottom: 100px;
        }
    }


    .vertical{
        display: flex;


        @media(min-width: 768px){
            flex-direction: row;
        }

        @media(min-width: 1000px){
            min-height: 80vh;
        }
    }

    fieldset {
        padding: 0;
        margin: 0;
        border: none;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 30px;
        display: flex;
        position:relative;

        @media(min-width: 768px){
            padding-left: 30px;
            padding-right: 30px;
            padding-top: 45px;
        }

    }

    p.desc {
        margin: 0px;
        margin-bottom: 40px;
        color: #555;
    }

    .form-row, .form-group, .form-date {
        width: 100%;

        @media(min-width: 992px){

        }
    }

    .form-label {
        display: block;
        width: 100%;
        font-size: 16px;
        margin-bottom: 10px;
    }

    .form-group, .form-date{
        margin-bottom: 18px;
        position: relative;
    }

    .form-flex, .form-date-group {
        display: flex;
        flex-direction: column;

    }

    .form-flex{

        @media(min-width: 768px){
            flex-direction: row;
            margin: 0 -10px;

            .form-group{
                width: 50%;
                padding: 0 10px;
            }
        }
    }

    .form-date-group{
        flex-direction: column;

        select {
            border: 1px solid #ebebeb;
            width: 100%;
            box-sizing: border-box;
            appearance: none!important;
            position: relative;
            background: 0 0;
            z-index: 10;
            cursor: pointer;
            padding: 0 20px;
            height: 50px;
            font-size: 14px;
            font-family: roboto slab;
            color: #999;
            box-sizing: border-box;
            background-color: #fff;
            color: #222;
            font-weight: 700;
        }

        @media(min-width: 480px){
            border: 1px solid transparent;
            border-radius: 5px;
        }
    }

    input, select{
        width: 100%;
        display: block;
        border: 1px solid #ebebeb;
        height: 50px;
        box-sizing: border-box;
        padding: 0 20px;
        color: #222;
        font-weight: 700;
        font-size: 14px;
        font-family: "Roboto Slab";
    }

    .text-input {
        font-size: 12px;
        color: #999;
        display: block;
        margin-top: 5px;
        span {
            color: #222;
            font-weight: 700;
        }
    }

    .form-date-item {
        position: relative;
        overflow: hidden;
        flex: 1;
        margin-right: 10px;

        &.error select{
            border-color: red;
        }
    }

    .buttons{
        display: flex;
        justify-content: center;
        margin-top: 50px;
        position:absolute;
        bottom: 0;
        width: 100%;


        .btn{
            width: 100%;
            text-transform: uppercase;
            font-weight: 700;
            font-size: .9em;
        }

        .btn + .btn{
            border-left: 2px #f8f8f8 solid;
        }
    }

    .btn{
        width: 140px;
        height: 50px;
        color: #fff;
        background: $blue-color;
        @extend display-flex;
        @include align-items(center);
        @include justify-content(center);
        text-decoration: none;
        font-family: "Arial";
        border: none;
        outline: none;
        cursor: pointer;

        &.success{
            background: #63a563;
        }
    }

    .fade-leave-active{
        transition: opacity .5s ease
    }

    .fade-enter-active{
    transition: opacity .5s ease 600ms;
    }
    .fade-enter, .fade-leave-to {
    opacity: 0;
    position: absolute;
    left: 0;
    top:84px;
    width: calc(100% - 60px);
    }

    .form-group.error {
        input{
            border-color: red;
        }
    }

    .help-block {
        color: red;
        font-style: italic;
        font-size: 12px;
        padding-top: 4px;
    }


</style>

